import React from "react"
import Layout from "../components/Layout"
import HeroImage from "../components/HeroImage"
import Content from "../components/Content"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

export default function Home({ data }) {
  let createMarkup = () => {
    return { __html: data.allWpPage.nodes[0].content }
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Startseite — Schülerwohnheim Forster GmbH</title>
      </Helmet>
      <HeroImage color="#b2fd4f" title={data.allWpPage.nodes[0].title} />
      <Content bgColor="#edfed8">
        <h1>{data.allWpPage.nodes[0].title}</h1>
        <span
          className="content container"
          dangerouslySetInnerHTML={createMarkup()}
        />
      </Content>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDo2" } }) {
      nodes {
        title
        id
        content
      }
    }
  }
`
